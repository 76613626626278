.test-remove-btn{
    padding: 0px;
    max-width: 20px;
    min-width: 20px;
    margin-bottom: 0;
    margin-right: 10px;
    position: sticky;
    /* left: -32%; */
    background-color: #343434;
    padding-top: 1%;
    padding-bottom: 1%;
}

.test-remove-btn:hover {
    background-color: black;
}

.selected-tests-container {
    max-height: 90%; 
    overflow-y: auto;
  }

.testname-sidebar{
    margin-left: 1%;
    position: relative;
}

.selected-tests-container {
    display: contents;
    flex-direction: column;
  }
  
  .test-container {
    display: contents;
    align-items: center;
  }
  
  .test-remove-btn {
    margin-right: 10px; 
  }
  
  .testname-sidebar {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .test-dropdown {
    outline: 5px solid orange;
    border-radius: 5px;
    animation: pulsate 1s infinite;
  }
  
  @keyframes pulsate {
    0% {
      outline-color: orange;
    }
    50% {
      outline-color: transparent;
    }
    100% {
      outline-color: orange;
    }
  }
  

  .test-container {
    position: relative; 
    display: flex;
    align-items: center;
    padding-left: 30px; 
  }
  
  .test-remove-btn {
    position: absolute;
    left: 0;
    margin-left: 5px;
  }
  
  .dndnode {
    flex-grow: 1;
  }
  