.App {
  display: flex;
  /* background-image: url("https://png.pngtree.com/thumb_back/fh260/background/20210408/pngtree-white-abstract-vector-web-background-design-image_597636.jpg");
  background-position-y: bottom ; */
  font-family: 'Arial', sans-serif;
  height: 85vh;
  width: 98.5vw;
}

.app-container {
  height: 85vh;
}


#list{
  display: flex;
  flex-direction: row;
}
.createnew{
  position: relative;
  left: 30px;
  width: 100%;
}
.excel-btn {
  width: 22%;
  height: 60px;
  border-radius: 12px;
  margin-top: 20px;
  background-color: #282828c2;
  margin-bottom: 0px;
  height: 5vh;
}

.excel-btn:hover{
  background-color: rgba(0, 0, 0, 0.863);
}

.excel-btn-top{
  width: 11%;
  border-radius: 12px;
  background-color: #282828c2;
  margin-bottom: 0px;
  height: 2vh;
  font-size: small;
  padding-top: 3px;
  margin-left: 5px;
}

.excel-btn-top:hover{
  background-color: black;
}

/*UMScss*/
/*registerpage css*/
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
}
 
.auth-form-card {
  width: 400px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
}
 
.register-divider {
  margin-bottom: 24px;
}
 
.register-form {
  display: flex;
  flex-direction: column;
  width: 300px;
}
 
.form-label {
  margin-bottom: 8px;
  width: 450px;
}
 
.form-input {
  width: 300px;
  margin-bottom: 16px;
}
 
.submit-btn {
  width: 300px;
  margin-bottom: 16px;
  
}
 
.login-btn {
  width: 300px;
  margin-bottom: 16px;
}

.login-btn:hover {
  background-color: #98340E !important;
  color: #fff !important;
  border-color: #fff !important;
}
 
/*loginpagecss*/
 
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
}
 
.auth-form-card {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
 }
 
.login-divider {
  margin-bottom: 24px;
  font-weight: bold;
}
 
.login-form {
  display: flex;
  flex-direction: column;
  width: 300px;
  
}
 
.form-label {
  margin-bottom: 8px;
}
 
.form-input {
  width: 300px;
  margin-bottom: 16px;
}
 
.submit-btn {
  width: 300px;
  margin-bottom: 16px;
  background-color: #DA291C;

}
.submit-btn:hover {
  background-color: #98340E !important;
}

/* .ant-btn-primary {
  background-color: #DA291C;
}

:global(.ant-btn-primary) {
  background-color: #DA291C;
  &:hover {
    background-color: #98340E !important;
    border-color: #98340E !important;
  }
} */
.logo-img {
  width:25%; 
  height:auto;
}

.register-btn {
  width: 300px;
  margin-bottom: 16px;
}
.register-btn:hover {
  background-color: #98340E !important;
  color: #fff !important;
  border-color: #fff !important;
}


.password-reset {
  text-decoration-line: underline;
  text-align: center;
  display: block; 
  transition: color 0.3s, text-decoration 0.3s; 
  cursor: pointer !important;
}

.password-reset:hover, .password-reset:active {
  /* text-decoration-line: none; */
  
  color:#98340E;
  ;
}


/* */
.error-message {
  color: red;
  margin-bottom: 10px; 
 }
/*UMScss*/

