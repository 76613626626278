.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode {
  height: 20px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  overflow: hidden;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}

.expansion {
  margin-top: 1%;
}

.expand-button{
  padding: 0;
  width: 10px;
  margin: 0;
  margin-left: 3%;
}

.submitFC-btn{
  height: 5%;
  width: 95%;
  margin-bottom: 10px;
  margin-left: 2.5%;
  background-color: #32de84bf;
}

.submitFC-btn:hover{
  background-color: #32de8480;
}

.react-flow__handle {
  min-width: 15px;
  height: 15px;
  border-radius: 3px;
}

.react-flow__handle.connecting {
  animation: bounce 2000ms infinite ease-out;
}