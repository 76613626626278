body {
  background-image: url("https://png.pngtree.com/thumb_back/fh260/background/20210408/pngtree-white-abstract-vector-web-background-design-image_597636.jpg");
  /* background-image: url("https://static.vecteezy.com/system/resources/previews/005/555/389/original/abstract-elegant-white-and-grey-background-abstract-white-pattern-squares-texture-free-vector.jpg"); */
  font-family: "Arial", sans-serif;
}

div input {
  width: 96%;
}

div select {
  width: 100%;
}

.form-container {
  max-width: 70%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 100em;
}

#border {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
}

label {
  font-weight: bold;
}
.step-description {
  padding-left: 10%;
}
.Samples-Container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
}

.Samples-Container > div {
  flex: 1;
  margin: 10px;
  display: flex;
  align-items: center;
}

.Samples-Container label {
  flex: 1;
  font-size: 14px;
}

.Samples-Container input {
  flex: 2;
}

.add-sample {
  background-color: #343434;
  width: 30%;
  font-size: 12px;
  padding: 5px 10px;
  margin-top: 5px;
}

.add-sample:hover {
  background-color: black;
}

input,
select {
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-container {
  display: flex; /* Enables flexbox layout */
  flex-direction: row !important; /* Aligns children (buttons) in a row */
  flex-wrap: nowrap !important; /* Prevents wrapping of items */
  align-items: center; /* Aligns items vertically in the center */
  justify-content: flex-start; /* Aligns items to the start of the container */
  gap: 10px; /* Adds space between each button */
  margin-left: 1.5%;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-flex-wrap-wrap {
  flex-wrap: nowrap !important;
}

.next-button,
.prev-button {
  display: inline-block;
  flex-direction: row;
  width: 27vw;
  margin-right: 10px;
}

.next-button {
  margin-right: 0px;
  /* margin-left: 105%; */
  border: 2px solid #007bff;
}

.save-button {
  background-color: #32de847a;
  /* width: 10% !important; */
  color: darkslategray;
  width: 100px;
}

.save-button:hover {
  background-color: #32de8447;
}

.goquo-button {
  /* background-color: #0888ff9f; */
  /* color: rgb(255, 255, 255);  */
  height: 200% !important;
  width: 48% !important;
  border: 2px solid #414141;
  background-color: white;
  color: darkslategray;
  cursor: pointer;
  transition: border-color 0.3s ease;
  /* padding-top: 8px;
  padding-bottom: 8px; */
}

.goquo-button:hover {
  /* background-color:#ffffffc9 ; */
  border: 2px solid #a1a1a1;
  background-color: white !important;
  color: black !important;
}

.inner-button-container {
  width: 60% !important;
}
.prev-button {
  border: 2px solid #007bff;
  background-color: white;
  color: darkslategray;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.prev-button:hover {
  /* background-color: #CEB888;  */
  border-color: lightblue;
}

.sub-button {
  background-color: #50c878;

  /* width: 10% !important; */
  width: 100px;
}

.sub-button:hover {
  background-color: #32de84;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* button:hover {
  background-color: #0056b3;
} */

.submitted {
  background-color: #4477d6;
  margin-top: 5%;
}

.submitted:hover {
  background-color: #5c88da;
}

.saved {
  color: blue;
  font-weight: bold;
  text-decoration: underline;
  transition: all 0.3s;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  transform: scale(1);
  margin-right: 22%;
  margin-top: 1%;
  margin-left: 22%;
  width: 55%;
}

.saved:hover {
  transform: scale(1.05);
}

.content-container {
  display: flex;
  justify-content: space-between;
}

.flow-chart-btn {
  width: 30%;
  margin-top: 5px;
  background-color: #4477d6;
  margin-bottom: 0px;
}

.flow-chart-btn:hover {
  background-color: #5c88da;
}

.cost-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cost-container div {
  flex: 1;
  margin-right: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.remove-sample {
  width: 20px;
  font-size: 12px;
  padding: 4px 0px;
  margin-right: 5px;
  margin-bottom: 7%;
  background-color: #ba0021;
}

.remove-sample:hover {
  background-color: #aa0000;
}

.seperation-container {
  border: 1px solid #ccc;
  padding: 10px;
  display: inline-flex;
  width: 97%;
}

.remove-purchase {
  width: 2%;
  font-size: 12px;
  padding: 4px 0px;
  margin-right: 5px;
  margin-bottom: 10px;
  background-color: #ba0021;
}

.remove-purchase:hover {
  background-color: #aa0000;
}

.purchase-info {
  width: 100%;
}

input[type="file"] {
  border: 0;
}
.samplesQuantity {
  margin-right: 3%;
  width: 10%;
}
.samplesPartNumber {
  width: 35%;
  margin-right: 3%;
}
.samplesRevision {
  width: 15%;
  margin-right: 3%;
}

.samplesPartName {
  margin-right: 2%;
  width: 35%;
}
.autofill {
  background-color: lightgrey;
}

#loading {
  height: 600px;
  display: flex;
  justify-content: center;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid black;
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container {
  margin-top: 20%;
}

.quotation-cancel {
  width: 100% !important;
}

.quotation-cancel:hover {
  background-color: transparent;
  color: #fd5c63;
}

.quotation-save {
  background-color: #fad5a5;
  color: black;
  width: 22% !important;
  border: 2px solid #007bff;
  background-color: white;
}

.quotation-save:hover {
  color: black !important;
  border-color: lightblue;
  background-color: white !important;
}

.view-datails {
  background-color: #fad5a5;
  color: black;
  width: 40% !important;
  border: 2px solid #007bff;
  background-color: white;
}

.view-datails:hover {
  color: black !important;
  border-color: lightblue;
  background-color: white !important;
}

.quotation-return {
  width: 40% !important;
}

.quotation-approval {
  background-color: #50c878;
  width: 40% !important;
}

.quotation-approval:hover {
  background-color: #32de84 !important;
}
.job-complete {
  background-color: #50c878;
  width: 100%; /* Let Flex container control the layout */
  margin-right: 0;
  margin-bottom: 0;
}

.job-complete:hover {
  background-color: #32de84;
}

.job-complete,
.save-details {
  width: 48%;
  max-width: 300px; /* Consistent max width */
  margin: 5px 0;
}

.closing-confirm {
  background-color: #50c878;
  width: 20% !important;
}

.closing-confirm:hover {
  background-color: #32de84 !important;
}

.cost-row:hover {
  background-color: transparent;
}

.ant-flex.css-dev-only-do-not-override-zl9ks2.ant-flex-wrap-wrap.ant-flex-gap-small {
  width: 125% !important;
}

.ant-spin-container {
  margin-top: 0;
}

.remove-file:hover {
  cursor: pointer;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  /* z-index: 1000;  */
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-button-container {
  flex-wrap: wrap; /* Ensure buttons wrap if necessary */
}
