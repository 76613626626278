/* General Table Styling */
.custom-hot-table {
  max-height: 90vh; /* Limiting height to viewport height */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: auto; /* Enables horizontal scrolling */
}

.hot-table-container {
  max-height: 75vh; /* Ensure the container doesn't take too much space */
  overflow: hidden; /* Avoid overflow issues */
}

/* Custom Handsontable Styling */
.htBold {
  font-weight: bold !important;
  background-color: #f0f0f0 !important;
}

.htDimmed3 {
  background-color: #e6f7ff !important;
  color: #a0a0a0 !important;
  pointer-events: none;
}

.htDimmed2 {
  background-color: #e6f7ff !important;
  font-weight: bold;
}

/* Header Styling */
.test-header-dt {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 6px 16px; /* Added horizontal padding */
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 98%; /* Full width to align with the table */
}

.test-name-dt {
  margin-left: 5%;
  font-size: 1.4em; /* Increased font size */
  font-weight: bold;
  color: #333;
}

.test-number-dt {
  font-size: 1em;
  color: #666;
}

.divider-dt {
  width: 2px;
  height: 20px;
  background-color: #ccc;
  margin: 0 12px;
}

.button-group-dt {
  margin-left: 60%;
  margin-top: 1%;
  display: flex;
  gap: 16px; /* Increased space between buttons */
  align-items: center; /* Align buttons vertically */
}

.save-button-dt, .exit-button-dt {
  font-size: 1.1em; /* Slightly larger font for readability */
}

.table-container {
  max-height: 75vh; /* Adjust as per your requirement */
  overflow-y: auto; /* Enable vertical scroll */
  overflow-x: auto; /* Enable horizontal scroll if necessary */
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 10px; /* Optional padding to give it space */
}

.highlight-red {
  background-color: #ffcccc !important;
  color: #a00;
}

.comment-border {
  border: 2px solid rgb(125, 125, 255) !important; /* Ensure the blue border for comments */
}

.file-border {
  border: 2px solid green !important; /* Ensure the green border for attached files */
}

/* Combine multiple classes when a cell has both comment and file */
.comment-border.file-border {
  border: 2px solid purple !important; /* Purple if a cell has both comments and files */
}

.highlight-red-file-border{
  background-color: #ffcccc !important;
  color: #a00;
  border: 2px solid green !important;
}

.highlight-red-comment-border{
  background-color: #ffcccc !important;
  color: #a00;
  border: 2px solid rgb(125, 125, 255) !important;
}
