 /* html, body {
    margin: 0;
    padding: 0;
    width: 100%; 
    height: 100%;
  } 
    nav {
    background-color: #000000;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    max-height: 40px;
    width: 97.5vw;
  }
    
  */



  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  nav {
    background-color: #000000;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    /* max-height: 40px; */
    width: 100%; 
    box-sizing: border-box; 
  }
  .logo {
    display: flex;
    align-items: center; 
    flex: 0 1 auto; 
  }
  
  .logo img {
    height: auto;
    width: 300px;
  }
  
  .nav-link {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }
  
  .nav-link li {
    margin-left: 15px; 
  }
  
  .nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }
  
  .nav-links li {
    margin-right: 15px; /* Space between links */
  }
  
  .nav-links a,
  .nav-link a {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    padding: 8px 8px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    
  }
  

  .nav-links a:hover,
  .nav-links a:focus {
    color: #fff;
    transform: scale(1.15); 
    transition: transform 0.3s ease;  
  }
  
  .logout-button{
    background-color: #DA291C;
    margin: 0;
  }
 
  .logout-button:hover {
    background-color: #98340E;
    transform: scale(1.05); 
    transition: transform 0.3s ease;  
  }

  .user-initials {
    width: 30px;
    height: 30px;

    color: #fdfdfddc;
    background-color: #fdfdfd5e; 
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 20px;
    font-size: 15px;
    font: bold;
    border: 2px solid; 
  }
  /* .user-initials:hover {
    transform: scale(1.15); 
    transition: transform 0.3s ease;  
  } */
  
  .switch-container {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .ant-switch {
    background-color: #4c4c4c;
  }