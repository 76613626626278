.manage-users-container {
    max-width: 60%;
    margin: 0 auto;
  }

  .user-list {
    list-style: none;
    padding: 0;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .user-list li {
    margin-bottom: 16px; 
    border: 1.5px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    width: 97%;
    display: flex;
    align-items: center; 
  }
  
  .user-name {
    flex: 1;
    margin-right: 20px;
  }
  
  .user-email {
    flex: 1;
    margin-right: 20px;
  }
  
  .user-role {
    appearance: none;
    margin-bottom: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: 1px solid #f9f9f9;
    padding: 6px; 
    font-size: 15px; 

    color: #333;
    background-color: #f9f9f9;
    cursor: pointer;
    border-radius: 4px; 
    position: relative;
    max-width: 150px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); 
    transition: border-color 0.2s, box-shadow 0.2s; 
  }

  .user-role:hover {
    border-color: #f9f9f9;
  }

  .user-role:focus {
    border-color: #4d90fe;
    box-shadow: 0 0 6px rgba(77, 144, 254, 0.5); 
  }

  .user-role option {
    padding: 6px; 
    font-size: 15px; 
    color: #555;
  }

  .user-role::after {
      content: '\U+25BC';
      font-size: 15px;
      color: #555;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
  }

  .user-role option:hover {
      background-color: #f9f9f9;
  }


  .user-row.eng {
    background-color: #f8f9f5;
    border-radius: 2%;
    border-color: red;
  }
  
  .user-row.pl {
    background-color: #f8f9f5;
    border-color: orange;
    border-radius: 2%;
  }
  
  .user-row.ccm {
    background-color: #f8f9f5;
    border-color: purple;
    border-radius: 2%;
  }

  .user-row.admin {
    background-color: #f8f9f5;
    border-color: blue;
    border-radius: 2%;
  }

  .white-background-list {
    background-color: white;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
   }
   
   .role-strip {
    width: 5px;
    height: 100%;
    display: inline-block;
    margin-right: 10px;
    border-radius: 2px;
   }

   .vertical-tag {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    margin-right: 10px;
   }

   .users-list{
    max-height: 80vh;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
   }

   .user-list::-webkit-scrollbar{
    display: none;
   }