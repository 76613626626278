.filter-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
}

@media (max-width: 1400px) {
  .filter-list {
    flex-direction: row;
    justify-content: center;
  }
}

#filter {
    /* max-width: 600px; */
    min-width: 200px;
    /* height: 20%; */
    width: 15%;
    padding: 20px;
    border-radius: 5px;
    background-color: rgba(255, 254, 254, 0.555);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    height: 500px;
    margin-top: 7.5%;
  }

  /* Medium screens (tablets, 768px and up) */
@media (min-width: 768px) {
  #filter {
    /* max-width: 150px; */
    width: 10%;
    padding: 10px;
    height: 600px;
    margin-top: 10vh;
  }
}

/* Large screens (desktops, 992px and up) */
@media (min-width: 1400px) {
  #filter {
    /* max-width: 300px; */
    width: 12%;
    padding: 15px;
    height: 600px;
    margin-top: 10vh;
  }
}

/* Extra large screens (large desktops, 1200px and up) */
@media (min-width: 1500px) {
  #filter {
    /* max-width: 350px; */
    width: 15%;
    padding: 20px;
    height: 680px;
    margin-top: 10vh;
  }
}
  .filter-toggle {

    background: #f9f9f9;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }
  
  .filter-toggle:hover {
    background: #e2e2e2;
  }

  
  
  /* Heading styles */
  h1 {
    font-size: 22px;
    text-align: left;
    margin-bottom: 20px;
    color: #3d3d3d;
  }
  
  /* Label styles */
  label {
    display: block;
    margin-bottom: 5px;
    color: #3d3d3d;
  }
  
  /* Select and Input styles */
  select,
  input,
  button {
    width: 98%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 16px;
  }
  
  .search{
    margin-top: 9%;
    background-color: #282828a2;
  }

  .search:hover {
    background-color: rgba(0, 0, 0, 0.63);
  }

  
  #new-job{
    position: relative;
    border-radius: 12px;
    width: 200px;
    height: 60px;
    color: white;
    background-color: #5A4FCF;
    transition: background-color 0.3s ease;
  }

  #new-job:hover {
    background-color: #0056b3;
  }

  img{
    width: 30px;
  }

  #searchTerm{
    width: 92%;
  }

  .year-counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  
  input[type="range"] {
    width: 200px;
    background: #e6e6e6;
    height: 6px;
    border-radius: 8px;
  }
    
  input[type="range"]::-webkit-slider-thumb {
    cursor: pointer;
  }

  .slider{
    width: 100%;
    height: 2px;
    background-color: #cdd9ed;
    margin-top: 30px;
  }
 
  .slider .thumb {
    width: 23px;
    height: 23px;
    cursor: grab;
    background-color: #ffff;
    border-radius: 50%;
    border: 2px solid #275efe;
    top: -12px;
  }


  /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 1;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #636363;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: black;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.multi-slider{
  margin-top: 5px;
}
.date-range-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; Adjust this if needed */
}
.rmdp-input{
  text-align: center;
}

.ant-notification-notice-wrapper{
  margin-top: 63px !important;
}

.ant-picker-header{
  width: 25% !important;
}
