.text-updater-node {
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  width: 350px;
}

.text-updater-node-expanded {
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  width: 500px;
}
h1 {
  text-align: center;
}

label {
  font-weight: bold;
  transition: font-size 0.2s ease;
}

input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  transition: font-size 0.2s ease;
}

input::placeholder {
  color: rgb(199, 199, 199);
}

.TestName {
  text-align: center;
  /* width: 150px;
    margin-left: 30%; */
}

.TestNumber {
  /* width: 150px; */
  display: flex;
  justify-content: center;
}

.TestNumber:hover {
  color: rgb(175, 175, 175);
}

.node-status {
  background-color: lightgreen;
  height: 10px;
  width: 100%;
  margin-bottom: 1px;
}

.details-button {
  background-color: #343434;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.details-button:hover {
  background-color: black;
}

.upload-button {
  background-color: #05be81;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.upload-button:hover {
  background-color: #006141;
}

.report-button {
  background-color: #9d73ff;
  width: 100%;
  padding-top: 5px;
  margin-top: 15px;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.report-button:hover {
  background-color: #613db3;
}

.viewreport-button {
  background-color: #348cff;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.viewreport-button:hover {
  background-color: #2564b6;
}

.testlog-button {
  background-color: #ffc251;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.testlog-button:hover {
  background-color: #947100;
}

.selectsample-button {
  background-color: #9b77ff;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.selectsample-button:hover {
  background-color: #4e2baf;
}

.group-container div {
  flex: 1;
  margin-right: 5px;
}

.node-content-container {
  display: flex;
  flex-direction: column;
}

.group-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.doc-link-btn {
  background-color: #3498db;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px;
  padding: 2px;
  height: 18px;
  width: 50%;
}

.doc-link-btn-reports {
  background-color: #3498db;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px;
  padding: 2px;
  height: 18px;
  width: 250px;
}

.node-inside-btn-container {
  display: flex;
  gap: 10px;
}
.status-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.status-container div {
  flex: 1;
}

.statusDropdown {
  padding: 2px;
  font-size: 14px;
  height: 27px;
  width: 50%;
}

.TIR {
  margin-top: 0px;
  padding: 2px;
  font-size: 14px;
  height: 27px;
  width: 15%;
}

.refresh-icon {
  position: absolute;
  left: 24px;
  top: 16px;
  z-index: 1000;
}

.tag-hover {
  transition: background-color 0.3s ease;
}

.tag-hover:hover {
  background-color: #d5f4b5;
}

.read-only {
  background-color: #f0f0f0;
  color: #666;
}

.wrapper {
  position: relative;
}

.TestNumber {
  position: relative;
  background: white;
  padding: 5px;
  z-index: 1;
  width: 40%;
  height: 50%;
  margin-left: 30%;
  margin-bottom: 10%;
  bottom: -1.2vh;
  border: 1px solid rgb(26, 26, 26);
  box-sizing: border-box;
  border-radius: 8px;
}

.node-status {
  position: absolute;
  top: 10;
  left: 10;
  right: 10;
  bottom: 10;
  border: 0px solid #000;
  box-sizing: border-box;
  height: 150%;
  border-radius: 8px;
}

.column-remove-btn {
  margin-bottom: 0px !important;
  /* margin-top: 10px !important; */
}

.ant-popconfirm-inner-content {
  width: 150px;
}

.ant-popconfirm-buttons {
  width: 70px;
}

@keyframes growIcon {
  0% {
    transform: translate(-50%, -50%) scale(0.6);
  }
  100% {
    transform: translate(-50%, -50%) scale(0.8);
  }
}

.grow {
  animation: growIcon 0.6s forwards;
}
