.dates-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* 
table {
  width: 100%;
  height: 50%;
  border-collapse: collapse;
  margin-top: 20px;
}

thead {
  background-color: #f2f2f2;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

input {
  width: 80px;
}

tr{
  max-height: 30px;
} */
