.navigation-panel {
  background-color: hsl(0, 0%, 97%);
  padding: 20px;
  padding-left: 0;
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.step-description {
  flex-grow: 1;
}

.step-description-item {
  height: 60px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  color: #333;
  background-color: #fff;
  transition: all 0.3s;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px; /* Default font size */
  letter-spacing: 1px;
  text-transform: uppercase;
  filter: grayscale(70%);
  cursor: pointer;
}

.step-description-item:hover {
  border-color: #1F51FF;
}

.step-description-item.active {
  font-weight: bold;
  color: #1F51FF;
  background-color: #f7f7f7;
  border-color: #1F51FF;
  filter: none;
}

/*Responsive Text Sizes */
@media (max-width: 1480px) {
  .step-description-item {
    font-size: 14px;
  }
}

@media (max-width: 1350px) {
  .step-description-item {
    font-size: 13px; 
  }
}

@media (max-width: 1250px) {
  .step-description-item {
    font-size: 12px; 
  }
}

@media (max-width: 1150px) {
  .step-description-item {
    font-size: 11px; 
  }
}

@media (max-width: 1050px) {
  .step-description-item {
    font-size: 7px; 
  }
}

@media (max-width: 750px) {
  .step-description-item {
    font-size: 5px; 
  }
}