#new-job{
    width: 65vw;
    background-color: #1e805ccb;
    margin-top: 20px;
    margin-right: 10px;
    margin-bottom: 0;
    height: 5vh;
    color: #ffffff;
}

#new-job:hover {
    background-color: #27a174cb ;
    transform: scale(0.97); 
    transition: transform 0.3s ease;
    
}
