.listOfJobs {
  padding-top: 0px;
  padding-left: 40px;
}

.tableContainer{
    height: 94.5%;
    overflow: scroll;
    width:80vw;
} 

.tableContainer-longer{
  height:100%;
  overflow: scroll;
  width:80vw;
  margin-top: 5px;
} 

/* Medium screens (tablets, 768px and up) */
/* @media (min-width: 1000px) {
  .listOfJobs {
    padding-left: 30px;
  }

  .tableContainer {
    height: 90%;
    width: 80vw;
  }

  .tableContainer-longer {
    width: calc(80vw - 30px);
  }
}

/* Large screens (desktops, 992px and up) */
/* @media (min-width: 1400px) {
  .listOfJobs {
    padding-left: 20px;
  }

  .tableContainer {
    height: 91.5%;
    width: 80vw;
  }

  .tableContainer-longer {
    width: calc(80vw - 20px);
  }
} */

/* Extra large screens (large desktops, 1200px and up) */
/* @media (min-width: 1500px) {
  .listOfJobs {
    padding-left: 10px;
  }

  .tableContainer {
    height: 94.5%;
    width: 100vw - 40px;
  }

  .tableContainer-longer {
    width: calc(100vw - 40px);
  }
} */

Table thead{
  position: sticky;
  top: 0px;
  z-index: 1;
}
  
  h2 {
    font-size: 20px;
    margin-bottom: 16px;
  }
  
  table {
    font-size: 16px;
    width: 98%;
    border-collapse: collapse;
    border: 1px solid #ddd;
    background-color: #fff;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border: 1px solid white;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  /* th:nth-child(even), td:nth-child(even) {
    background-color: #f2f2f2;
  } */
  
  tr:hover {
    background-color: #e0e0e0;
  }
  
  #remove {
    height: 35px;
    width: 50px;
    background: transparent;   
    color: #da291c;                  
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
    border: 2px solid #da291c; 
    border-radius: 5px;       
    cursor: pointer;          
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
    padding: 5px 5px; 
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;

  }

  #remove:hover {
    transform: scale(1.05);             
    background-color: #f54f43;          
    color: white;                       
  }

  #inactivate{
    color: whitesmoke;
    background-color: #da291c;
    transform: scale(1);
    margin-bottom: 5px;
    padding: 10px 5px 10px 5px;
  }

  #inactivate:hover{
    /* transform: scale(1.05);  */
    background-color: #f54f43;
  }
  #unassign{
    height: 35px;
    width: 40px;
    background: transparent;   
    color: #da291c;                  
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
    border: 2px solid #da291c; 
    border-radius: 5px;       
    cursor: pointer;          
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
    padding: 5px 5px; 
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
  }

  #unassign:hover{
    transform: scale(1.05);             
    background-color: #f54f43;          
    color: white;    
  }
  #unassign_tt{
    height: 35px;
    width: 40px;
    background: transparent;   
    color: #3e4ae8;                  
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
    border: 2px solid #3e4ae8; 
    border-radius: 5px;       
    cursor: pointer;          
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
    padding: 5px 5px; 
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
  }

  #unassign_tt:hover{
    transform: scale(1.05);             
    background-color: rgb(57, 31, 123);          
    color: white;    
  }
  #close_job{
    color: whitesmoke;
    background-color: #00b16d;
    transform: scale(1);
    margin-bottom: 5px;
    padding: 10px 5px 10px 5px;
  }

  #close_job:hover{
    /* transform: scale(1.05);  */
    background-color: #007447;
  }
  

  #inactivate_eng{
    color: whitesmoke;
    background-color: #da291c;
    transform: scale(1);
    margin-top: 5px;
    margin-bottom: 0;
    padding: 10px 5px 10px 5px;
  }

  #inactivate_eng:hover{
    transform: scale(1.05); 
    background-color: #f54f43;
  }

  #eval-btn{
    font-size: 13px;
    padding: 10px 5px 10px 5px;
    margin-bottom: 0;
    background-color: #282828 ;
  }

  #eval-btn:hover{
    transform: scale(1.05); 
    background-color: #4e4e4e;
  }
  
  #editFC{
    font-size: 85%;
    margin: 0px;
    color: white;
    background-color: #333333c9	;
    transform: scale(1);
  }

  #editFC:hover{
    background-color: #5c88da;
    transform: scale(1.05); 
  }

  #edit{
    font-size: 85%;
    height: 20%;
    margin: 0px;
    background-color: #333333c9	;
    transform: scale(1);
    color: rgb(255, 255, 255);
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
  }

  #edit:hover{
    background-color: #00db5bc4;
    transform: scale(1.05); 
  }
  #view-report {
    font-size: 85%;
    margin: 0px;
    background-color: #333333c9; 
    color: rgb(255, 255, 255);  
    padding: 10px 20px;         
    white-space: nowrap;        
    overflow: hidden;          
    text-overflow: ellipsis;     
    transform: scale(1);         
    border: none;                
    cursor: pointer;            
    transition: transform 0.2s ease, background-color 0.2s ease; 
}

#view-report:hover {
    background-color: #2582fc;   
    transform: scale(1.05);      
}



  .button-container {
    display: flex;
    flex-direction: row !important;  
    align-items: center;  
    justify-content: flex-start; 
    width: 100%;         
  }



  
  

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: none;
  }
  
  .overlay.active {
    display: block;
  }
/* 
  .saved-row{
    background-color: #e9ffe9 !important
  } */
  
  .saved-row:hover {
    cursor: pointer;
  }

.ant-modal .ant-modal-content {
    position: relative;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-radius: 8px;
    box-shadow: none !important;
    pointer-events: auto;
    padding: 20px 24px;
}

.highlighted-row {
  background-color: #e9ffe9 !important; 
}



  /* .overlay {
    display: none; 
    position: fixed; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    background-color: rgba(0,0,0,0.1); 
    z-index: 2; 
  }
  
  .overlay.active {
    display: block; 
  }
  
  .modal {
    z-index: 3; 
  } */

  .download-all-btn{
    margin-bottom: 3px;
    max-width: 30%;
    margin-left: 20px !important;
    background-color: white;
    color: black;
    border: 1px solid rgb(112, 112, 215);
    transition: border-color 0.3s ease;
  }

  /* .download-all-btn:hover {
    border-color: lightblue;
  } */