.code-input {
    display: inline-block;
    margin-right: 5px;
    width: 60px;
    height: 60px;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 24px;
    border-radius: 5px;
    color: #3d85c6;
 }
 .code-input::placeholder {
    color: grey;
 }
 .code-input:focus {
    outline: none;
    border-color: #3d85c6;
 }