.viewJob {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 20px;
}

  .assignment-btn {
    margin-bottom: 2px;
    background-color: #38bd7a;
    font-size: 85%; 
    padding: 10px 5px 10px 5px;
  }
  
  .assignment-btn:hover {
    background-color: #0b8100;
  }
  
  .view-assignment-btn {
    
    margin-bottom: 2px;
    background-color: #414141;
    font-size: 85%; 
    padding: 10px 5px 10px 5px;
  }
  
  .view-assignment-btn:hover {
    background-color: #006ed481;
  }
  

.section {
  flex: 1;
  margin-right: 20px;
  margin-left: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  width: 700px;
  max-width: 700px;
}

.section h3 {
  background-color: #102030;
  color: #fff;
  padding: 15px;
  margin: 0;
  border-bottom: 1px solid #fff;
}

.general-information,
.job-description,.terms-and-conditions-section {
  padding: 15px;
}

.job-description-tabs h3 {
  background-color: #102030;
  color: #fff;
  padding: 15px;
  margin-bottom: 5px;
  border-bottom: 1px solid #102030;
}

.tabs {
  display: flex;
}

.tab {
  flex: 1;
  background-color: #DA291C;
  color: #fff;
  border: none;
  padding: 8px 15px; 
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 12px;
  border-radius: 0px;
}

.tab:hover {
  background-color: #98340E;
}

.tab.active {
    background-color: #823109;
    border-bottom: 2px solid #fff;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.job-description-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 0px;
}

.job-description-content > div:not(:first-child) {
  display: none;
}

.job-description-content div {
  line-height: 1.6;
}

.quote-section {
  /* border: 1px solid #ccc;
  padding: 20px; */
  margin: 20px;
  /* border-radius: 8px;
  background-color: #f8f8f8;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.quote-section p {
  margin: 5px 0;
}

.quote-section p:first-child {
  font-size: 1.2em;
  font-weight: bold;
}

.close-button {
  position: absolute;
  top: -7px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 15px;
  cursor: pointer;
  width: 5px;
  color: black;
}

.close-button:hover {
  color: grey;
}

ul.no-bullets {
  list-style-type: none;
  padding-left: 10px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

li.no-bullets {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

li.no-bullets:hover {
  background-color: #e9ecef;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
/* 
input[type="file"] {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #818589	;
  border: 1px solid #818589	;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  width: 20%;
} */

/* input[type="file"]:hover {
  background-color: #B2BEB5	;
  border-color: #B2BEB5	;
}

input[type="file"]::-webkit-file-upload-button {
  display: none;
} */

.fileList {
  padding: 0;
  margin-left: 5%;
}

.fileList li {
  margin-bottom: 10px;
  padding: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.fileList li:hover {
  background-color: #e9ecef;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


